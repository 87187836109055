
.focused,
.blurred {
  padding-bottom: 20px; /* Assuming you want both states to have 20px padding */
}

.focused {
  filter: none;
}

.blurred {
  filter: blur(2px);
}

.userInput {
  opacity: 1;
  position: absolute;
}

.letter {
  font-size: 30px;
}

.focused {
  border: 1px solid black;
}

.blurred {
  border: 1px solid gray;
}

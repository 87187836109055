.App {
  text-align: center;
}

/* @media (min-width: 1498px) {
  html {
    zoom: 0.8;
    -moz-transform: scale(0.8);
    -moz-transform-origin: 0 0;
  }
} */


/* @media (min-width: 3840px), (min-resolution: 192dpi) {
  html {
    zoom: 0.8;
    -moz-transform: scale(0.8);
    -moz-transform-origin: 0 0;
  }
} */